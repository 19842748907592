var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-basic-btn',_vm._g(_vm._b({attrs:{"id":("activator-" + _vm._uid),"x-loading":_vm.xLoading,"x-enabled":_vm.xEnabled,"x-disabled":_vm.xDisabled}},'x-basic-btn',_vm.combinedAttrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),(
    _vm.options.target &&
    _vm.options.target.menu &&
    (!_vm.options.target.menu.ext || !_vm.options.target.menu.ext.disabled)
  )?_c('x-menu',{attrs:{"activator":("#activator-" + _vm._uid),"x-options":_vm.options.target.menu,"x-context":_vm.self}}):(
    _vm.options.target &&
    _vm.options.target.confirm &&
    (!_vm.options.target.confirm.ext || !_vm.options.target.confirm.ext.disabled)
  )?_c('x-confirm-dialog',{attrs:{"activator":("#activator-" + _vm._uid),"x-options":_vm.options.target.confirm,"x-context":_vm.self}}):(
    _vm.options.target &&
    _vm.options.target.dialog &&
    (!_vm.options.target.dialog.ext || !_vm.options.target.dialog.ext.disabled)
  )?_c('x-dialog',{key:("dialog-" + _vm._uid),attrs:{"activator":("#activator-" + _vm._uid),"x-options":_vm.options.target.dialog,"x-context":_vm.self}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }