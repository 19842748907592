





















































import defaultComponent from "../utils/defaultComponent";
import merge from "../utils/merge";

export default defaultComponent.apply({
  props: ["xLoading", "xEnabled", "xDisabled"],
  inheritAttrs: false,
  data() {
    return {
      combinedAttrs: {}
    };
  },
  methods: {
    initialize() {
      this.combinedAttrs = merge.clone(this.$attrs, this.$props);
    },
    onWithoutClick(on) {
      const newOn = {};
      for (const k in on) {
        if (k === "click") {
          continue;
        }
        newOn[k] = on[k];
      }
      return newOn;
    }
  },
  extraOptions: {
    disableInheritClass: true
  }
});
